.header {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        background: none;
        border: none;
    }
}