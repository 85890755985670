.wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.resend {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 155px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 102%;
    background-color: #fff;
    max-width: 505px;   
    border-radius: 5px;
    border: 1px solid rgb(210, 210, 215);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    li {
        cursor: pointer;
        padding: 0 9px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        max-width: 155px;
        width: 100%;
        button {
            margin-top: 10px;
            font-weight: 600;
            color: rgb(0, 102, 204);
            background: none;
            border: none;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;

            @media (any-hover: hover){
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        img {
            max-height: 50px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: #333;
            text-align: center;
        }
    }
}

.message {
    color: rgb(0, 102, 204);
    font-size: 14px;
    cursor: pointer;
}