@use "sass:math";
@import "../../mixins.scss";

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  height: 156px;

  label {
    position: relative;
    margin: 0;



    input {
      width: 100%;
      height: 56px;
      padding-left: 15px;
      padding-top: 18px;
      padding-right: 16px;
      background-color: hsla(0, 0%, 100%, 0.8);
      border: 1px solid #86868b;
      border-radius: 12px;
      font-size: 17px;
      padding-right: 25px;

      &.secondActive {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.active {
        & ~ span {
          font-size: 12px;
          top: 15%;
          transform: none;
-webkit-transition: all 0.1s ease-in;
-moz-transition: all 0.1s ease-in;
-o-transition: all 0.1s ease-in;
transition: all 0.1s ease-in;
        }
      }

      &:focus {
        border-color: #0071e3;
        border-width: 2px;
        & ~ span {
          font-size: 12px;
          top: 15%;
          transform: none;
-webkit-transition: all 0.1s ease-in;
-moz-transition: all 0.1s ease-in;
-o-transition: all 0.1s ease-in;
transition: all 0.1s ease-in;
        }
      }
    }

    span {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      -webkit-transition: all 0.1s ease-in;
      -moz-transition: all 0.1s ease-in;
      -o-transition: all 0.1s ease-in;
      transition: all 0.1s ease-in;
      color: rgb(110, 110, 115);
      font-size: 17px;
      font-weight: 400;
      font-family: "SF Pro Text";
      max-width: 80%;
    }

    button {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:disabled {
        cursor: auto;
      }
    }

    &:nth-child(2) {
        input {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
  }

  &.error {
    input {
        border-color: #e30000;
        background-color: rgb(255, 235, 238);
    }

    span {
        color: #e30000;
    }
  }
}
