@use "sass:math";
@import "../../mixins.scss";

.wrap {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  display: flex;
  flex-direction: column;

  input {
    border: 1px solid rgb(134, 134, 139);
    border-radius: 8px;
    font-size: 24px;
    color: rgb(73, 73, 73);

    &.error {
        border: 1px solid #e30000;
    }

    &:focus {
      border: 2px solid rgb(0, 113, 227);
    }
  }

  input {
    @include adaptiveValue("width", 42, 38);
    @include adaptiveValue("height", 42, 38);
  }
}


.error {
    color: #e30000;
    font-size: 14px;
}
