.adminPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .block {
    flex: 1 1 45%; /* Ширина блока 45%, адаптивная */
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #f69c00; /* Оранжевая рамка */
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      color: #f69c00; /* Оранжевый цвет заголовка */
    }

    textarea {
      width: 100%;
      height: 150px;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      resize: none;
      font-size: 1rem;
    }

    button {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #f69c00; /* Оранжевый цвет кнопки */
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    button:hover {
      background-color: #e98200; /* Темно-оранжевый цвет при наведении */
    }
  }
}

.brsLi {
  padding: 30px;
  border: 1px solid #fff;
}

.brs {
  display: flex;
  align-items: center;
  gap: 30px;
}

body {
  overflow: auto;
  overflow-x: hidden;
}