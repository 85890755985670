.loader {
    max-width: 640px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 11px 34px 0 rgba(0, 0, 0, .2);
    border-radius: 34px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 640px;
}