.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.form {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.inputGroup {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input {
  width: calc(100% - 20px); /* Учитываем отступы */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.button {
  width: calc(100% - 20px); /* Учитываем отступы */
  padding: 10px;
  background-color: #ff7f0e; /* Оранжевый цвет */
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #e66800; /* Темно-оранжевый цвет при наведении */
}
