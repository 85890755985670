.footer {
    padding: 30px 15px;
    color: rgba(0, 0, 0, 0.56);
    background-color: rgb(242, 242, 247);
}

.container {
    max-width: 1035px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 11px;
}


.left {
    display: flex;
    align-items: center;
    column-gap: 15px;
    row-gap: 5px;
    flex-wrap: wrap;
}