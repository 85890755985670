.login {
    max-width: 640px;
    width: 100%;
}

.container {
    max-width: 640px;
    width: 100%;
    padding: 30px;  
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    box-shadow: 0 11px 34px 0 rgba(0, 0, 0, .2);
    border-radius: 34px;
    background-color: #fff;

    @media (max-width: 767px){
          box-shadow: none;
          padding: 0;
    }

    p {
        max-width: 400px;
        color: rgb(73, 73, 73);
    }

    h2 {
        font-size: 21px;
        font-weight: 400;
        text-align: center;
        margin-top: 30px;
        color: rgb(73, 73, 73);
    }

    a {
        color: rgb(0, 102, 204);

        &:hover {
            text-decoration: underline;;
        }
    }
}

.bottomBlock {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    align-items: center;

    li {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;

        a {
            padding: 20px;
            border-radius: 12px;
        }

        a:hover {
            text-decoration: none;
            background: rgba(0, 0, 0, .08);
        }

        svg {
            opacity: 0.5;
        }

        p {
            color: rgb(128, 128, 128);
            font-size: 14px;
        }
    }
}

.divider {
    max-width: 440px;
    background-color: rgba(216,216,216,0.7);
    width: 100%;
    height: 1px;
}

.sent {
    line-height: 25px;
    margin-bottom: -10px;
}

.stolen {
    font-size: 14px !important;
    color: #000 !important;
}