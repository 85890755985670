* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
	font-family:'SF Pro Display';
	font-style:normal;
	font-weight:400;
	src:url('../public/fonts/sf-pro-display_regular.woff2');
	src:local('☺'), url("../public/fonts/sf-pro-display_regular.woff2") format("woff2");
}

@font-face {
	font-family:'SF Pro Display';
	font-style:normal;
	font-weight:600;
	src:url('../public/fonts/sf-pro-display_semibold.woff2');
	src:local('☺'), url("../public/fonts/sf-pro-display_semibold.woff2") format("woff2");
}

@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:400;
	src:url('../public/fonts/sf-pro-text_regular.woff2');
	src:local('☺'), url("../public/fonts/sf-pro-text_regular.woff2") format("woff2");
}

@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:600;
	src:url('../public/fonts/sf-pro-text_semibold.woff2');
	src:local('☺'), url("../public/fonts/sf-pro-text_semibold.woff2") format("woff2");
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Text";
  letter-spacing: normal;
}

input, textarea, button, a {
  font-family: "SF Pro Text";
  font-weight: 400;
}

h1,h2,h3,h4,h5,h6 {
  font-family: "SF Pro Display";
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  border: none;
  outline: none;
  background: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}


.loading-spinner {
  width: 40px;
  animation: spin12 0.75s steps(12, end) infinite;
}

@keyframes spin12 {
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner__line {
  stroke: #cacaca;
  stroke-width: 5;
  stroke-linecap: round;
}

.loading-spinner__line:nth-child(12n+0) { stroke-opacity: 0.08; }
.loading-spinner__line:nth-child(12n+1) { stroke-opacity: 0.17; }
.loading-spinner__line:nth-child(12n+2) { stroke-opacity: 0.25; }
.loading-spinner__line:nth-child(12n+3) { stroke-opacity: 0.33; }
.loading-spinner__line:nth-child(12n+4) { stroke-opacity: 0.42; }
.loading-spinner__line:nth-child(12n+5) { stroke-opacity: 0.5; }
.loading-spinner__line:nth-child(12n+6) { stroke-opacity: 0.58; }
.loading-spinner__line:nth-child(12n+7) { stroke-opacity: 0.66; }
.loading-spinner__line:nth-child(12n+8) { stroke-opacity: 0.75; }
.loading-spinner__line:nth-child(12n+9) { stroke-opacity: 0.83; }
.loading-spinner__line:nth-child(12n+11) { stroke-opacity: 0.92; }
