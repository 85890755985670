@use "sass:math";
@import "../../mixins.scss";

.login {
  max-width: 640px;
  width: 100%;

  opacity: 0;
  visibility: hidden;
  display: none;

  &.active {
    opacity: 1;
    visibility: visible;
    display: flex;
  }
}

.container {
  max-width: 640px;
  width: 100%;
  @include adaptiveValue("padding-top", 40, 20);
  @include adaptiveValue("padding-left", 80, 20);
  @include adaptiveValue("padding-right", 80, 20);
  padding-bottom: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 20px;
  box-shadow: 0 11px 34px 0 rgba(0, 0, 0, 0.2);
  border-radius: 34px;
  background-color: #fff;

  @media (max-width: 767px){
      border-radius: 0;
      box-shadow: none;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    margin-right: 48px;
    margin-left: 48px;
  }

  div.links {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 10px;
  }

  a {
    color: #06c;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 30px;
  color: rgb(73, 73, 73);
}
